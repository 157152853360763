
























import {Component, Prop, Vue} from "vue-property-decorator"
import {OrderSelect, OrderType} from "@/models/Order"
import {getModule} from "vuex-module-decorators"
import LangModule from "@/store/LangModule"
import {EventStatus} from "@/models/Event";

@Component
export default class EventStatusComponent extends Vue {

  lang: any = getModule(LangModule).lang

  @Prop() readonly eventStatus!: EventStatus

  eventStatuses = [
    {name: "En Espera", value: EventStatus.WAITING},
    {name: "En Progreso", value: EventStatus.IN_PROGRESS},
    {name: "Finalizado", value: EventStatus.FINISHED},
    {name: "Aplazado", value: EventStatus.POSTPONED},
    {name: "Cancelado", value: EventStatus.CANCELLED},
  ]

}
