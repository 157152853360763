








































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import {MultipleItem} from "@/handlers/interfaces/ContentUI"
import Event, {EventStatus} from "@/models/Event"
import {getModule} from "vuex-module-decorators"
import LangModule from "@/store/LangModule"
import Handler from "@/handlers/Handler"
import EventService from "@/services/EventService";
import EventStatusComponent from "@/components/EventStatusComponent.vue";

@Component({ components: {EventStatusComponent} })
export default class EventsView extends Vue {
  lang: any = getModule(LangModule).lang
  loading: boolean = false

  events: MultipleItem<Event> = { items: [], totalItems: 0 }
  page: number = 1
  size: number = 10
  search = ""
  picker: string = ""
  dates: string[] = [this.picker]

  status: Nullable<EventStatus> = null

  headers = [
    {text: "Id", value: "id", width: "60px", align: "center"},
    {text: "Titulo", value: "title", width: "auto", align: "center"},
    {text: "Empieza", value: "startsAt", width: "200px", align: "center"},
    {text: "Termina", value: "endsAt", width: "200px", align: "center"},
    {text: "Estado", value: "status", width: "130px", align: "center"},
  ]

  eventStatuses = [
    {name: "En Espera", value: EventStatus.WAITING},
    {name: "En Progreso", value: EventStatus.IN_PROGRESS},
    {name: "Finalizado", value: EventStatus.FINISHED},
    {name: "Aplazado", value: EventStatus.POSTPONED},
    {name: "Cancelado", value: EventStatus.CANCELLED},
  ]

  created() { this.refresh() }

  async refresh() {
    try {
      await Handler.getItems(this, this.events, () =>
          EventService.findEvents(this.page - 1, this.size, this.search, this.dates[0], this.dates[1], this.status)
      )
    } catch (e) { console.log(e) }
  }

  rowClick(event: Event) {
    this.$router.push("/activities/" + event.id)
  }

  @Watch('status')
  onStatusChanged() {
    this.refresh()
  }
}
